
import CloudFun, { computed, defineComponent, ref } from '@cloudfun/core'
import Grid, { GridOptions } from '@/cloudfun/components/Grid.vue'
import ColorPicker from "@/cloudfun/components/ColorPicker.vue";
import { VxeFormProps } from 'vxe-table'

export default defineComponent({
  components: {
    Grid,
    ColorPicker
  },
  setup() {
    const model = CloudFun.current?.model
    const grid = ref<any>({})
    var printColumns = [{ field: 'Name' }, { field: 'Description' }]

    const gridOptions: GridOptions = {
      id: 'color',
      title: '顏色',
      multiselect: false,
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      printConfig: {
        sheetName: '顏色清單',
        columns: printColumns,
        modes: ['current', 'selected', 'all']
      },
      exportConfig: {
        filename: '顏色清單',
        type: 'csv',
        types: ['html', 'csv'],
        mode: 'all',
        modes: ['current', 'selected', 'all'],
        columns: printColumns
      },
      columns: [
        {
          field: "Number",
          title: "顏色編號",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "text" },
          },
        },
        {
          field: "Name",
          title: "顏色名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "text" },
          },
        },
        {
          field: "HexValue",
          title: "顯示色碼",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          slots: { default: "color" },
        },
      ],
      promises: {
        query: model
          ? (params) => model.dispatch('color/query', params)
          : undefined,
        queryAll: model ? () => model.dispatch('color/query') : undefined,
        save: model
          ? (params) => model.dispatch('color/save', params)
          : undefined
      },
      modalConfig: { showFooter: true }
    }

    const formOptions = {
      titleWidth: 60,
      titleAlign: "right",
      items: [
        {
          field: "Number",
          title: "顏色編號",
          span: 24,
          itemRender: {
            name: "$input",
            props: {
              placeholder: "請輸入顏色編號"
            },
          },
        },
        {
          field: "Name",
          title: "顏色名稱",
          span: 24,
          itemRender: {
            name: "$input",
            props: {
              placeholder: "請輸入顏色名稱"
            },
          },
        },
        {
          field: "HexValue",
          title: "代表色碼",
          span: 24,
          slots: { default: "color-picker" },
        }
      ],
      rules: {
        Number: [
          {
            type: "string",
            max: 128,
            required: true,
            message: "請輸入顏色編號",
          },
        ],
        Name: [
          {
            type: "string",
            max: 128,
            required: true,
            message: "請輸入級別名稱",
          },
        ],
      },
    };

    return {
      grid,
      gridOptions,
      formOptions
    }
  }
})
